import axios from "axios";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

// Create an Axios instance
const apiClient = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor to attach the token to headers
apiClient.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");

    // Skip adding the Authorization header for login and signup routes
    const isAuthRequest =
      config.url?.includes("/accounts/login/") ||
      config.url?.includes("/accounts/signup/");

    // If the token exists, add it to the Authorization header
    if (token && !isAuthRequest) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle any error in the request
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response, // If the response is successful, just return it
  (error) => {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error(error.response.data); // Log the error to the console
      // Extract the error message
      const errorMessage =
        error.response.data.detail || "Something went wrong.";
      // Show the error in a toast notification
      toast.error(errorMessage, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "error-toast", // Apply custom CSS class
        style: {
          backgroundColor: "#f8d7da", // Custom background color for error
          color: "#721c24", // Custom text color
          border: "1px solid #f5c6cb", // Custom border color
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        },
      });
    }

    // Reject the promise so the calling function knows the request failed
    return Promise.reject(error);
  }
);

export default apiClient;
